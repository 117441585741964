.markbox{
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}
.badgepurple{
    color: #ffffff;
    background-color: #A020F0;
}

.absencedetails{
    font-size: 18px;
    padding: 5px 14px;
}
.status{
    margin: auto 16px;
    font-size: 16px;
}
.status_tage{
    font-weight: 700;
}
.view_status{
    text-align: right;
}
/* Comment Colunm */
.comment{
    font-size: 14px;
}
.btn{
    font-weight: 400;
}

.cell-wrap-text div{
    display: block;
    width: 100%;
}