.language-switcher-container
{
  position: relative;

  .language-switcher{
    background-color: rgba(68, 64, 84, 0.1);
    display: inline-block;
    padding: 7px;
    margin-top: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover{
      background-color: rgba(68, 64, 84, 0.2);
    }
  }

  ul {
    display: none;
    list-style: none;
    align-items: center;
    position: absolute;
    flex-direction: column;
    right: 0;
    min-width: 240px;
    background: #FFF;
    padding-bottom: 10px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    margin-top: -5px;
    overflow: hidden;
  }

  &:hover {
    ul {
      display: flex;
    }
  }

  li {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  li:first-child{
    padding: 0;
    position: relative;
    cursor: default;
    margin-bottom: 10px;

    &:before{
      display: block;
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      filter: grayscale(80%);
      opacity: 0.5;
    }

    span {
      z-index: 8;
      color: #FFF;
      font-size: 14px;
      min-height: 40px;
      justify-content: center;
      padding: 1.5rem 0.5rem;
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      background-color: rgba(68, 64, 84, 0.9);
    }
  }

  &:hover{
    li.language-option{
      display: flex;
    }
  }

  .active-language
  {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }

  li {
    &.language-option {
      cursor: pointer;
      font-size: .88rem;
      transition: background-color .3s ease, color .3s ease;
    }

    &.active{
      background-color: #545cd8;
      color: #fff;
      text-decoration: none;
    }

    &:hover:not(.active) {
      background-color: #e0f3ff;
      color: #1e2125;
    }
    .lang-flag {
      display: block;
      height: 24px;
      width: 24px;
      margin-right: 15px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}