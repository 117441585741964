.textandsubtext{
  line-height: 2;
  margin-left: 15px;
}
.alignitem{
  align-items: center;
}
.text{
  color: #808080;
  font-weight: 700;
  white-space: break-spaces;
}
.subtext{
  color: #808080;
  font-weight: 300;
}